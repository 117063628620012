import { useFloating, useDismiss, useInteractions, useHover, autoUpdate, shift, useRole, safePolygon, FloatingPortal, offset, FloatingFocusManager, useListNavigation, size, useTransitionStatus, useTransitionStyles, useClick } from '@floating-ui/react'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useScrollDirection } from '/machinery/useScroll'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { LinkLogo } from '/features/buildingBlocks/LinkLogo'
import { HamburgerWithReference } from '/features/pageOnly/menu/Hamburger'
import { ButtonLinkRed } from '/features/buildingBlocks/Button'
import { LabelUnderline } from '/features/buildingBlocks/LabelUnderline'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './HeaderLanding.css'

import chevronIcon from '/images/icons/chevron-down.raw.svg'

export function HeaderLanding({ menu, formId, layoutClassName }) {
  const { metScrollThreshold: isScrolledDown } = useScrollDirection({ deltaThreshold: 50, scrollThreshold: 100 })

  return (
    <header className={cx(styles.component, layoutClassName)}>
      <ContainerXl>
        <div className={styles.container}>
          <Logo {...{ isScrolledDown }} />
          <Menu {...{ menu, formId }} />
        </div>
      </ContainerXl>
    </header>
  )
}

function Logo({ isScrolledDown }) {
  const language = useLanguage()

  return (
    <div className={cx(styles.componentLogo, isScrolledDown && styles.isScrolledDown)}>
      <LinkLogo href={routeMap.app.home({ language })} />
    </div>
  )
}

function Menu({ menu, formId }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const contactLabel = isViewportMd ? __`contact-us-now` : 'Contact'

  const hasMenu = Boolean(menu?.length)

  return (
    <div className={cx(styles.componentMenu, hasMenu && styles.hasMenu)}>
      {hasMenu && <DropdownMenu label={__`our-labels`} {...{ menu }} />}
      <ButtonLinkRed href={`#${formId}`} dataX='link-to-contact-form' label={contactLabel} />
    </div>
  )
}

function DropdownMenu({ label, menu }) {
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm) ?? false

  const {
    getReferenceProps,
    getFloatingProps,
    getTransitionWrapperProps,
    getItemProps,
    isOpen,
    context
  } = useFloatingProps()

  return (
    <>
      {isViewportSm
        ? <DropdownButtonDesktop {...{ isOpen, label, getReferenceProps }} />
        : <DropdownButtonMobile {...{ isOpen, getReferenceProps }} />
      }

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager modal={false} {...{ context }}>
            <div className={styles.floatingContainer} {...getFloatingProps()}>
              <ul className={styles.submenuContainer} {...getTransitionWrapperProps()}>
                {!isViewportSm && <span className={styles.mobileLabel}>{label}</span>}
                {menu.map(({ title, corporateLink: link }, i) => link &&
                  <SubmenuItem
                    key={i}
                    index={i}
                    layoutClassName={styles.submenuItemLayout}
                    {...{ title, link, getItemProps }}
                  />
                )}
              </ul>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

function DropdownButtonDesktop({ isOpen, label, getReferenceProps }) {
  return (
    <button type='button' className={styles.componentDropdownButtonDesktop} {...getReferenceProps()}>
      <span className={styles.submenuButtonLabel}>
        <LabelUnderline {...{ label }} />
      </span>
      <span className={cx(styles.iconContainer, isOpen && styles.isOpen)}>
        <Icon icon={chevronIcon} />
      </span>
    </button>
  )
}

function DropdownButtonMobile({ isOpen, getReferenceProps }) {
  return (
    <div className={styles.componentDropdownButtonMobile}>
      <HamburgerWithReference layoutClassName={styles.hamburgerLayout} {...{ isOpen, getReferenceProps }} />
    </div>
  )
}

function SubmenuItem({ index, title, link, getItemProps, layoutClassName }) {
  return (
    <li className={cx(styles.componentSubmenuItem, layoutClassName)}>
      <a
        href={link}
        data-x='link-in-menu'
        className={styles.submenuItemAnchor}
        {...getItemProps({ index })}
      >
        {title}
      </a>
    </li>
  )
}

function useFloatingProps() {
  const [activeIndex, setActiveIndex] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const listRef = React.useRef([])

  const { floatingStyles, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [
      shift(),
      offset({ crossAxis: -24, mainAxis: 30 }),
      size()
    ],
  })

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: { opacity: 0.1, transform: 'scale(0.995) translateY(-5px)' },
    close: { opacity: 0.1, transform: 'scale(0.995) translateY(-5px)' },
    open: { opacity: 1, transform: 'scale(1) translateY(0px)' },
  })

  const listNavigation = useListNavigation(context, {
    onNavigate: setActiveIndex,
    activeIndex,
    listRef,
  })

  const click = useClick(context)
  const hover = useHover(context, { handleClose: safePolygon({ requireIntent: false }) })
  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context, { referencePress: false, outsidePress: true })

  const { getReferenceProps, getItemProps, getFloatingProps } = useInteractions([
    click, listNavigation, hover, role, dismiss
  ])

  return {
    getReferenceProps: x => getReferenceProps({ ...x, ref: refs.setReference }),
    getFloatingProps: x => getFloatingProps({ ...x, ref: refs.setFloating, style: floatingStyles }),
    getTransitionWrapperProps: x => ({ style: transitionStyles }),
    getItemProps: x => getItemProps({
      ...x,
      ref: el => { listRef.current[x.index] = el },
      tabIndex: activeIndex === x.index ? 0 : -1
    }),
    isOpen: isMounted,
    context
  }
}
